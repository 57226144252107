import * as React from 'react'
import cn from 'classnames'
import styles from './Body.module.scss'

export type BodyProps = React.ComponentPropsWithoutRef<'p'>

export const Body: React.VFC<BodyProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <p className={cn(styles.body, className)} {...restProps}>
      {children}
    </p>
  )
}
