import * as React from 'react'
import cn from 'classnames'
import type { PropsWithRequiredChildren } from '../../../types/helpers'
import styles from './Root.module.scss'
import upgrade from '../../../images/upgrade.png'

export type RootProps = PropsWithRequiredChildren<
  React.ComponentPropsWithoutRef<'section'>
>

export const Root: React.VFC<RootProps> = (props) => {
  const { children, className, ...restProps } = props

  return (
    <section className={cn(styles.root, className)} {...restProps}>
      <article className={styles.content}>{children}</article>
      <img src={upgrade} className={styles.image} alt="" />
    </section>
  )
}
