import * as React from 'react'
import { FeatureGate } from 'src/design-system'
import { useRailsContext } from 'components/rails-context'

type FeatureGatePageProps = {
  body: string
  buttonTitle: string
  title: string
}

export const FeatureGatePage: React.VFC<FeatureGatePageProps> = (props) => {
  const { body, buttonTitle = 'Get feature', title } = props

  const { org } = useRailsContext()

  const upgradeLink = `/orgs/${org?.slug}/billing/new`

  return (
    <FeatureGate.Root>
      <FeatureGate.Title dangerouslySetInnerHTML={{ __html: title }} />
      <FeatureGate.Body dangerouslySetInnerHTML={{ __html: body }} />
      <FeatureGate.Button as="a" href={upgradeLink}>
        {buttonTitle}
      </FeatureGate.Button>
    </FeatureGate.Root>
  )
}
