import * as React from 'react'
import cn from 'classnames'
import styles from './Title.module.scss'
import { UpgradeTag } from '../../..'

export type TitleProps = React.ComponentPropsWithoutRef<'header'>

export const Title: React.VFC<TitleProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <header className={cn(styles.header, className)}>
      <UpgradeTag className={styles.tag} />
      <h2 className={styles.title} {...restProps}>
        {children}
      </h2>
    </header>
  )
}
